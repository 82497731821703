const PLAN_SEE_ALL_BUTTON = '.js-bh_plan-see-all';
const PLAN_HIDDEN_DATA = '.js-bh_plan-hidden';
const PLAN_VISIBLE_CLASS = 'bh_plan-visible';

$(document).ready(function () {
  const $hiddenData = $(PLAN_HIDDEN_DATA);
  $(PLAN_SEE_ALL_BUTTON).on('click', function () {
    const $this = $(this);
    if ($hiddenData.hasClass(PLAN_VISIBLE_CLASS)) {
      $hiddenData.slideUp().removeClass(PLAN_VISIBLE_CLASS);
      $this.removeClass(PLAN_VISIBLE_CLASS);
      $('html,body').animate({
        scrollTop: $('.bh_plan-main').offset().top - $('.bh_nav').height(),
      });
    } else {
      $hiddenData.slideDown().addClass(PLAN_VISIBLE_CLASS);
      $this.addClass(PLAN_VISIBLE_CLASS);
    }
  });
});
