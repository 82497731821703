const MOBILE_BREAKPOINT = 1024;
const NAV = '.js-bh_nav';
const EXPANDABLE_NAV_ITEMS_CLASS = 'js-bh-nav-expandable';
const ALL_NAV_ITEMS = '.bh_nav__links-item';
const LI_NAV_LINK = '.bh_nav__link';
const NAV_DROPDOWN_OPEN_CLASS = 'bh-nav--dropdown-open';
const NAV_MOBILE_TOGGLE_OPEN = '.js-bh_nav__toggle-open';
const NAV_MOBILE_TOGGLE_CLOSE = '.js-bh_nav__toggle-close';
const NAV_MOBILE_OPEN_CLASS = '--mobile-open';
const NAV_MOBILE_RIGHT_SIDE = '.js-hb_nav-right';
const NAV_MOBILE_FIRST_TAB = '.js-bh_nav__logo-link';
const NAV_MOBILE_LAST_TAB = '.js-bh_nav__login-sign';
const NAV_SCROLLED_CLASS = 'bh-nav--scrolled';


$(document).ready(function() {
  const $navItems = $('.' + EXPANDABLE_NAV_ITEMS_CLASS);
  const $allNavItemsLI = $(ALL_NAV_ITEMS);
  const $navToggleOpen = $(NAV_MOBILE_TOGGLE_OPEN);
  const $navToggleClose = $(NAV_MOBILE_TOGGLE_CLOSE);
  const $nav = $(NAV);

  $allNavItemsLI.each(function(i) {
    const $this = $(this);
    const $liLink = $this.find(LI_NAV_LINK);
    $liLink.on('focus', function(i) {
      if ($(window).width() > MOBILE_BREAKPOINT) {
        $navItems.removeClass(NAV_DROPDOWN_OPEN_CLASS);
        $navItems.find(LI_NAV_LINK).attr("aria-expanded","false");
        if ($this.hasClass(EXPANDABLE_NAV_ITEMS_CLASS)) {
          $this.addClass(NAV_DROPDOWN_OPEN_CLASS);
          $liLink.attr("aria-expanded","true");
        }
      }
    });
    $this.on('mouseover', function(i) {
      if ($(window).width() > MOBILE_BREAKPOINT && $this.hasClass(EXPANDABLE_NAV_ITEMS_CLASS)) {
        $(this).addClass(NAV_DROPDOWN_OPEN_CLASS);
        $liLink.attr("aria-expanded","true");
      }
    });

    $this.on('mouseout', function(i) {
      if ($(window).width() > MOBILE_BREAKPOINT && $this.hasClass(EXPANDABLE_NAV_ITEMS_CLASS)) {
        $(this).removeClass(NAV_DROPDOWN_OPEN_CLASS);
        $liLink.attr("aria-expanded","false");
      }
    });
  });

  $navItems.each(function(i) {
    const $this = $(this);
    const $liLink = $(this).find(LI_NAV_LINK);
    $liLink.on('click', function(e) {
      if ($(window).width() <= MOBILE_BREAKPOINT) {
        e.preventDefault();
        $this.toggleClass(NAV_DROPDOWN_OPEN_CLASS);
        $liLink.attr('aria-expanded', function (i, attr) {
          return attr == 'true' ? 'false' : 'true'
        });
      }
    })
  })

  function setCloseFocus() {
    $navToggleClose.focus();
  }

  $navToggleOpen.on('click', function() {
    $nav.addClass(NAV_MOBILE_OPEN_CLASS);
    $allNavItemsLI.removeClass(NAV_DROPDOWN_OPEN_CLASS);
    setTimeout(setCloseFocus, 100);
  });

  $navToggleClose.on('click', function() {
    $nav.removeClass(NAV_MOBILE_OPEN_CLASS);
    $allNavItemsLI.removeClass(NAV_DROPDOWN_OPEN_CLASS);
    $navItems.find(LI_NAV_LINK).attr("aria-expanded","false");
    $navToggleOpen.focus();
  });

  // Trap focus in mobile menu
  const $rightSide = $(NAV_MOBILE_RIGHT_SIDE);
  const $mobileMenuFirstTabbable = $rightSide.find(NAV_MOBILE_FIRST_TAB);
  const $mobileMenuLastTabbable = $rightSide.find(NAV_MOBILE_LAST_TAB);

  $mobileMenuLastTabbable.on('keydown', function (e) {
    if ($(window).width() <= MOBILE_BREAKPOINT) {
      if ((e.which === 9 && !e.shiftKey)) {
        e.preventDefault();
        $mobileMenuFirstTabbable.focus();
      }
    }
  });

  $mobileMenuFirstTabbable.on('keydown', function (e) {
    if ($(window).width() <= MOBILE_BREAKPOINT) {
      if ((e.which === 9 && e.shiftKey)) {
        e.preventDefault();
        $mobileMenuLastTabbable.focus();
      }
    }
  });

  $(window).on('scroll', $.throttle(() => {
    const $win = $(this);
    if ($win.scrollTop() > $nav.height()) {
      $nav.addClass(NAV_SCROLLED_CLASS);
    } else {
      $nav.removeClass(NAV_SCROLLED_CLASS);
    }
  }, 100));
});