const EXPANDABLE_FOOTER_ITEMS = '.bh_footer-item';
const EXPANDABLE_FOOTER_BUTTON = '.bh_footer-item-title';
const EXPANDABLE_FOOTER_CONTAINER = '.bh_footer-item-container';
const EXPANDABLE_FOOTER_BUTTON_IMAGE = '.bh_footer-mobile-dropdown';
const FOOTER_MOBILE_OPEN_CLASS = '--mobile-open';
const FOOTER_MOBILE_ROTATE_IMAGE = '--rotate-image';
const MOBILE_DEVICE_BREAKPOINT = 767;

const LOCATION_DROPDOWN_BUTTON = '.bh_footer-location-dropdown';
const LOCATION_DROPDOWN_OPEN = '--dropdown-open';

$(document).ready(function() {
  const $expandableItems = $(EXPANDABLE_FOOTER_ITEMS);
  const $expandableButton = $(EXPANDABLE_FOOTER_BUTTON);
  const $expandableContainer = $(EXPANDABLE_FOOTER_CONTAINER);
  const $expandableButtonImage = $(EXPANDABLE_FOOTER_BUTTON_IMAGE);
  const $locationDropdownButton = $(LOCATION_DROPDOWN_BUTTON);

  $($expandableItems).each(function(){
    const $this = $(this);
    const $dropdownButton = $this.find($expandableContainer);
    $expandableButton.attr("aria-expanded", function(i, attr){
      if(attr === undefined) return "false";
      });
    $this.find($expandableButton).click(function(){
      if($(window).width() <= MOBILE_DEVICE_BREAKPOINT){
      const $this = $(this)
        const $dropdownImage = $this.find($expandableButtonImage);
        $dropdownButton.toggleClass(FOOTER_MOBILE_OPEN_CLASS);
        $dropdownImage.toggleClass(FOOTER_MOBILE_ROTATE_IMAGE);
        $expandableButton.attr("aria-expanded", function(i, attr){
          return attr === "false" ? "true" : "false";
        });
        }
    })
  })

  $($locationDropdownButton).click(function(e){
    const $this = $(this);
    $this.toggleClass(LOCATION_DROPDOWN_OPEN);
    if($this.not(LOCATION_DROPDOWN_OPEN)){
      $($this).scrollTop(0);
    }
  })

});