    

$(document).ready(function () {
    const $landing2Modal = $("#bhModal");
    const $openButton = $("#bhOpenModal");
    const $closeButton = $("#bhCloseModal");

    $openButton.click(function(){
        $landing2Modal.css("display", "block");
    })
    $closeButton.click(function(){
        $landing2Modal.css("display", "none");
    })

    $landing2Modal.click(function(){
        $landing2Modal.css("display", "none");
    })
});