const STATISTIC_CAROUSEL = '.js-bh_statistic-carousel';

$(document).ready(function () {
  $(STATISTIC_CAROUSEL).slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  });

  // Landing Page Carousels
  $('.js-bh-logo-slides').slick({
    infinite: false,
    slidesToShow: 7,
    slidesToScroll: 7,
    dots: false,
    prevArrow: false,
    nextArrow: false,
    responsive: [
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
        },
      },
    ],
  });
});
