$(function () {
  $('.bh_faq').on('click', function () {
    const currentF = $(this);
    if (!currentF.hasClass('active')) {
      currentF.addClass('active');
      currentF.find('.bh_faq-answer').slideDown();
    } else {
      const isLink = currentF.attr('href');
      if (typeof isLink == 'undefined') {
        currentF.removeClass('active');
        currentF.find('.bh_faq-answer').slideUp();
      }
    }
    $('.bh_faq')
      .not(currentF)
      .removeClass('active')
      .find('.bh_faq-answer')
      .slideUp();
  });
});
