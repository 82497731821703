const FEATURES_CAROUSEL = '.bh_hp-features__slideshow-slides';
const INTEGRATIONS_CAROUSEL = '.bh_hp-integrations__slides';
const THEME_CAROUSEL = '.bh-home-themes';

$(document).ready(function () {
  // Homepage Hero
  $('.bh-hero-inp').on('keyup', function () {
    const currentVal = $(this).val();
    const baseLink = $('.js-hero-btn').attr('data-link');
    const newLink = baseLink + '&email=' + currentVal;
    $('.js-hero-btn').attr('href', newLink);
  });

  // Theme Carousel
  $(THEME_CAROUSEL).slick({
    centerMode: false,
    slidesToShow: 3,
    infinite: false,
    variableWidth: true,
    prevArrow: $(
      '.bh_hp-themes__control.--left'
    ),
    nextArrow: $(
      '.bh_hp-themes__control.--right'
    ),
    responsive: [
      {
        breakpoint: 630,
        settings: {
          centerMode: false,
          slidesToShow: 2,
          infinite: false,
          variableWidth: true,
        }
      },
      {
        breakpoint: 500,
        settings: {
          centerMode: false,
          slidesToShow: 1,
          infinite: false,
          variableWidth: true,
        }
      }
    ]
  });

  // Features Carousel
  $(FEATURES_CAROUSEL).on('init', function (event, slick, a) {
    $('.bh_hp-features__grid li:first').addClass(
      'bh_hp-features__grid-item--active'
    );
  });
  $(FEATURES_CAROUSEL).on(
    'beforeChange',
    function (event, slick, currentSlide, nextSlide) {
      $('.bh_hp-features__grid-item--active').removeClass(
        'bh_hp-features__grid-item--active'
      );
      $('.bh_hp-features__grid li:nth-child(' + (nextSlide + 1) + ')').addClass(
        'bh_hp-features__grid-item--active'
      );
    }
  );
  $(FEATURES_CAROUSEL).slick({
    infinite: true,
    prevArrow: $(
      '.bh_hp-features__slideshow-slide-controls--left, .bh_hp-features__slideshow-slide-bottom-controls--left'
    ),
    nextArrow: $(
      '.bh_hp-features__slideshow-slide-controls--right, .bh_hp-features__slideshow-slide-bottom-controls--right'
    ),
  });
  $('.bh_hp-features__grid li').click(function () {
    var slide = parseInt($(this).attr('slide'));
    $(FEATURES_CAROUSEL).slick('slickGoTo', slide);
  });

  $(INTEGRATIONS_CAROUSEL).slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow: $(
      '.bh_hp-integrations__slideshow-button--previous, .bh_hp-integrations__slideshow-button--previous'
    ),
    nextArrow: $(
      '.bh_hp-integrations__slideshow-button--next, .bh_hp-integrations__slideshow-button--next'
    ),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          variableWidth: true,
        },
      },
    ],
  });
});
