const REVIEW_CAROUSEL = '.js-bh_review-carousel';

$(document).ready(function() {
  $(REVIEW_CAROUSEL).slick({
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [
      {
        breakpoint: 630,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
    ]
  });
});