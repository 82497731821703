const IMAGE_TEXT_CAROUSEL = '.bh_image-text-slider__slides';

$(document).ready(function() {
  $(IMAGE_TEXT_CAROUSEL).slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    prevArrow: $('.bh_image-text-slider__slideshow-button--previous, .bh_image-text-slider__slideshow-button--previous'),
    nextArrow: $('.bh_image-text-slider__slideshow-button--next, .bh_image-text-slider__slideshow-button--next'),
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          variableWidth: true
        }
      },
    ]
  });
});
